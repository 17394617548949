@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?31uxgy');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?31uxgy#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?31uxgy') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?31uxgy') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?31uxgy##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-smallarrow {
  &:before {
    content: $ico-smallarrow; 
  }
}

