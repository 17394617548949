/*
Theme Name: Start WordPress
*/
@import 'normalize';
@import 'variables';
@import 'bxslider';
@import 'slick';
@import 'typo';
html
{
  box-sizing: border-box;
}
*, *:before, *:after
{
  box-sizing: inherit;
}
@import 'button';

/*
 css page principale
*/

body
{

  font-family: 'Poppins', sans-serif;
}
.center
{
  text-align: center;
}
.wrapper
{
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
}
.header
{
  height: 100px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding-right: 29px;
}
/*
.header *:first-child{
    margin-left: 0;
    margin-right: auto;
}
*/

.logo
{
  margin-left: 0;
  margin-right: auto;

}
.logo-site
{

  display: block;

}
/* lien Header */

.menu-nav-top > li
{

  list-style: none;
  display: inline-block;
  padding-left: 17px;
  padding-right: 17px;

}
.menu-nav-top > li a
{

  color: #6F6F6F;
  font-size: 13px;
  text-decoration: none;
  letter-spacing: 0.08em;
  text-transform: uppercase;

}
.sociaux
{

  display: flex;
  flex-wrap: nowrap;
}
.img-sociaux
{

  padding-left: 12px;
  padding-right: 12px;
}
.jointeam-content
{

  display: flex;

}
.jointeam
{

  width: 207px;
  height: 99px;
  position: relative;
  margin-left: 50px;
  justify-content: flex-end;
}
.jointeam-content_rectangle
{

  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #6CCBDD;
  width: 207px;
  height: 74px;

}
.jointeam-content_text
{
  height: 74px;
  display: flex;
  flex-wrap: wrap;
  width: 95px;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 0;
  padding-left: 23px;
  color: #FFF;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;

}
.jointeam-content_logo
{

  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;

}
/* menu */

.header ul
{

}
.menu-nav-top
{
  display: flex;
  margin: 0;
}
/* navigation */

.navigation
{

  background: #272727;
  display: flex;
  align-items: center;
  justify-content: space-around;

}
.menu2-nav-top
{

  display: flex;
  padding: 0;
  margin: 0;

  > li
  {

    display: inline-block;
    padding-left: 34px;
    padding-right: 34px;

  }

  > li a
  {

    display: flex;
    height: 83px;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    text-transform: uppercase;
    color: #FFF;
    text-decoration: none;
    letter-spacing: 0.08em;

  }

  > li a span
  {
    position: relative;
    display: block;
  }
  > li a span::before
  {
    content: '';
    display: block;
    background-color: aqua;
    height: 4px;
    width: 0;
    position: absolute;
    bottom: -6px;
    right: 50%;
    transition: width .2s ease-in-out;
  }
  > li a:hover span::before
  {
    width: 50%;
  }

  > li a span::after
  {
    content: '';
    display: block;
    background-color: aqua;
    height: 4px;
    width: 0;
    position: absolute;
    bottom: -6px;
    left: 50%;
    transition: width .2s ease-in-out;
  }
  > li a:hover span::after
  {
    width: 50%;
  }
}
/*
.lien-menu2-nav span {
    
    border-bottom: 4px solid transparent;
}

.lien-menu2-nav:hover span {
    
    border-bottom-color: aqua;
}
*/

/* slider */

.slider
{

  position: relative;
  width: 1360px;
  height: 589px;

}
.slider ul
{
  margin: 0;
  padding: 0;

}
.slider ul li
{

  list-style: none;

}
.slider-image1
{

  display: block;
  height: 589px;
  width: 100%;

}
.slider .bx-wrapper
{

  border: none;
  box-shadow: none;

}
/* rectangle bleu + promo + profitez de l'offre + credit impôt */

.promo
{

  position: absolute;
  height: 589px;
  width: 589px;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;

}
.promo a
{

  text-decoration: none;
}
.promo-rectangle_bleu
{

  font-family: "omnes-pro", sans-serif;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  height: 324px;
  width: 407px;
  background: #73CCDC;
  bottom: 124px;
  left: 121px;
  text-transform: uppercase;

}
.promo-text_titre
{

  display: block;
  color: #FFF;
  font-size: 45px;
  padding-top: 55px;
  padding-left: 60px;
  max-width: 315px;

}
.promo-text_content
{

  display: block;
  color: #FFF;
  font-size: 27px;
  padding-left: 60px;

}
.promo-rectangle_bleu__profitez
{

  @extend .btn-base;
  width: 261px;
  margin-left: 64px;
  margin-top: 27px;

}
.promo-profitez_text
{

  color: #FFF;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;

}
/* crédit d'impôt */

.impot-text
{

  transform: rotate(-55deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 15px;
  right: 5px;

}
.promo-rectangle_30
{

  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #FFF;
  text-transform: uppercase;

}
.promo-30pourcent
{

  color: #FFF;
  font-size: 40px;
  font-family: 'Oswald', sans-serif;

}
.promo-rectangle_blanc
{

  width: 110px;
  height: 120px;
  transform: rotate(55deg);
  background: #FFF;
  border-radius: 18px;
  margin-top: 175px;
  margin-left: 80%;
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.16);

}
.promo-rectangle_vert
{

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 110px;
  background: #8FC449;
  border-radius: 15px;
  position: absolute;
  margin: 5px;

}
/* numero + devis */

.numero-devis
{

  position: absolute;
  top: 5px;
  right: 0;

}
.numero-devis_content
{

  margin-top: 60px;
  width: 200px;
  height: 120px;
  position: relative;

}
/* numéro de tel */

.numero-tel_rectangle
{

  position: absolute;
  right: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 60px;
  width: 200px;
  background-color: #7BAF36;

}
.numero-tel_image
{

  width: 12px;
  height: 19px;
  margin-right: 13px;
}
.numero-tel_text
{

  color: #FFF;

}
/* devis */

.devis-gratuit_rectangle
{

  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  background-color: #272727;
  height: 60px;
  width: 200px;
}
.devis-gratuit_text
{

  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: #FFF;
  text-transform: uppercase;

}
.bandeau-info
{

  margin: 0;
  padding: 0;

}
.engagements
{

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;

}
.bandeau-info li
{

  display: flex;
  font-size: 16px;
  height: 100px;
  list-style: none;
  border: 1px solid #EEE;
  border-left: none;
  width: 25%;
  align-items: center;

}
.bandeau-info li:first-child
{

  border-left: 1px solid #EEE;
}
.logo-engagements
{

  margin-left: 51px;
  width: auto;
  height: 45px;
  margin-right: 5px;
}
/* Partie Nos expertises */

.expertise-titre
{

  border-bottom: 4px solid #73CCDC;
  font-family: "Omnes-pro", sans-serif;
  font-weight: 400;
  font-size: 40px;
  text-transform: uppercase;

}
.expertise-content
{

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 50%;
  margin-top: 75px;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;

  &_text
  {
    line-height: 25px;
    padding-bottom: 25px;
  }

  & > *
  {
    padding-top: 13px;
  }

}
.bloc-onglets
{

  display: flex;
  background: #F5F5F5 url(images/bgd_texture.png);
  width: auto;
  position: relative;
  flex-wrap: wrap;
  overflow: hidden;

}
.expertise-fond_text
{

  font-family: "omnes-pro", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 155px;
  color: #E5E5E6;
  display: block;
  top: 0;
  left: -27px;
  position: absolute;
  z-index: 1;

}
.onglets
{

  display: flex;
  width: 400px;
  height: 400px;
  background-color: #E6E7E9;
  margin: 10px;
  flex-direction: column;
  align-items: center;
  position: relative;

  .promo
  {
    position: absolute;
    top: -4px;
    left: -4px;
    background: url(images/promo_expertise.png) top left no-repeat;
    width: 168px;
    height: 111px;

    span
    {
      color: #FFF;
      font-family: 'Oswald', sans-serif;
      font-size: 28px;
      position: relative;
      display: block;
      transform: rotate(-32deg);
      text-align: center;
      left: -23px;
      top: 22px;
    }
  }

}
.bloc-onglets_content
{

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 2;
  padding-bottom: 98px;

}
.bloc-onglets_content a
{

  text-decoration: none;
}
.bloc-expertises
{
  background: #F5F5F5;
  .onglets
  {
    background: #F5F5F5 url(images/bgd_texture.png);
  }
  .expertise-fond_text
  {
    color: #ECECEC;
  }
}
.text-onglet_bleu
{

  font-family: "Omnes-pro", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  color: #73CCDC;
  margin-top: 40px;

}
.img-onglets
{

  margin-top: 62px;
  width: 140px;
  height: 100px;

}
.onglet-bouton
{
  @extend .btn-base;
  min-width: 190px;
  height: 40px;
  border-radius: 20px;
  margin-top: 30px;
}
.promo-profitez_text
{
  display: flex;
  align-items: center;
  justify-content: center;
  span
  {
    padding: 0 30px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child
    {
      background-color: #484848;
      border-radius: 20px 0 0 20px;
    }
  }
}
.header .header-button
{

  display: none;
}
.header .nav-button
{

  display: none;
}
.parrainage
{

  position: relative;

  &-cont
  {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    left: 0;
    padding-right: 162px;
    position: absolute;
    right: 0;
    top: 0;

    a
    {
      text-decoration: none;
    }
  }

  &-bloc
  {
    height: 426px;
    background: #73CCDC url('images/parrainagebloc.png') top center no-repeat;
    color: #FFF;
    width: 299px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
    padding-right: 50px;

    &_title
    {
      font-family: "omnes-pro", sans-serif;
      text-transform: uppercase;
      font-size: 67px;
      text-align: center;
      line-height: 66px;
      padding-bottom: 15px;
      &::first-line
      {
        font-family: 'Oswald', sans-serif;
        font-size: 55px;
      }

    }

    &_text
    {
      font-size: 20px;
      text-align: center;
    }
  }
}
.partenaires
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 217px;
  padding: 0 158px;

  &-title
  {
    font-size: 27px;
    letter-spacing: 0.04em;
    line-height: 32px;
    text-transform: uppercase;
    font-family: "omnes-pro", sans-serif;
  }

  &-logos
  {
    flex-grow: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img
    {
      margin-left: 40px;
    }

  }
}
.slider-engagements
{
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  .slider-wrapper
  {
    min-width: 2269px;
    width: 2269px;
  }
  .slick-dots
  {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 56px;

    li
    {
      list-style-type: none;
      padding: 0 4px;

      button
      {
        background-color: #272727;
        border: 0;
        font-size: 0;
        height: 8px;
        width: 8px;
        max-width: 8px;
        min-width: 8px;
        padding: 0;
        border-radius: 4px;
        cursor: pointer;
        outline: 0;
      }

    }
    .slick-active
    {
      button
      {
        background-color: #73CCDC;
      }
    }

  }
}
.engagement
{
  height: 350px;
  width: 743px;
  display: flex !important;
  justify-content: flex-end;
  align-items: center;

  &-icons
  {
    padding-bottom: 6px;
  }
  &-title
  {
    font-family: "omnes-pro", sans-serif;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    font-size: 37px;
  }
  &-text
  {
    padding: 15px 40px 0;
    text-align: center;
    font-size: 16px;
    line-height: 25px;
  }
  &-wrap
  {
    width: 743px;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 65px;
    .engagement-cont
    {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .engagement-icons
      {
        text-align: center;
        img
        {
          display: inline-block;
        }
        img:last-child
        {
          display: none;
        }
      }
    }
  }
  &-img
  {
    display: block;
  }
  &.slick-active,
  &.slick-center
  {
    height: 350px;
    width: 743px;
    display: flex !important;
    align-items: center;

    .engagement-icons
    {
      padding-bottom: 6px;
    }
    .engagement-title
    {
      font-family: "omnes-pro", sans-serif;
      font-style: normal;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      font-size: 37px;
    }
    .engagement-text
    {
      padding: 15px 40px 0;
      text-align: center;
      font-size: 16px;
      line-height: 25px;
    }
    .engagement-wrap
    {
      width: 743px;
      height: 353px;
      background-color: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      .engagement-cont
      {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .engagement-icons
        {
          text-align: center;
          img
          {
            display: inline-block;
          }
          img:last-child
          {
            display: none;
          }
        }
      }
    }
    .engagement-img
    {
      display: block;
    }
  }

  &.slick-slide:not(.slick-active),
  &.last-child:not(.slick-active),
  &.first-child:not(.slick-active)
  {
    .engagement-img
    {
      display: none;
    }
    .engagement-wrap
    {
      background-color: #73CCDC;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 300px;
      width: 300px;
      .engagement-text
      {
        display: none;
      }
      .engagement-icons
      {
        text-align: center;
        img
        {
          display: inline-block;
        }
        img:first-child
        {
          display: none;
        }
      }
    }
    .engagement-title
    {
      color: #FFF;
      font-size: 30px;
      font-weight: 400;
      position: relative;
      padding-bottom: 25px;
      &::before
      {
        background-color: #FFF;
        content: '';
        display: block;
        height: 3px;
        width: 65px;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -(65px/2);

      }
    }
  }
  &.first-child,
  &.slick-current
  {
    justify-content: flex-end;
    .engagement-wrap
    {
      margin-right: 65px;
    }
    ~ .slick-center
    {
      justify-content: flex-start;
      .engagement-wrap
      {
        margin-left: 65px;
      }
    }
  }
  &.slick-active
  {
    .engagement-wrap
    {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    + li
    {
      justify-content: flex-start;
      .engagement-wrap
      {
        margin-left: 65px;
      }
    }
  }

}
.news
{
  display: flex;
  padding-bottom: 53px;

  .bloc-onglets
  {
    background: none;
    width: 578px;
    padding-bottom: 35px;
    .expertise-fond_text
    {
      font-size: 155px;
      color: #F8F7F7;
      left: -10px;
    }
    .expertise-titre
    {
      display: inline-block;
    }
    .expertise-content
    {
      width: 100%;
      & > *
      {
        width: 100%;
        padding-left: 130px;
      }
    }
    .news-text
    {
      padding-left: 130px;
      padding-right: 94px;
      font-size: 16px;
      line-height: 25px;
      text-align: justify;
    }
  }
  &-content
  {
    width: 782px;
  }
  .wrap-btn
  {
    padding-top: 30px;
  }
}
.btn-actu
{
  height: 44px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #272727;
  border-radius: 22px;
  padding: 0 30px;
  text-decoration: none;
  border: 1px solid #272727;
  font-size: 16px;
  i
  {
    margin-left: 12px;
    font-size: 35px;
  }
  &:hover
  {
    border-color: #6CCBDD;
    color: #6CCBDD;
  }
}
.project
{
  background-color: #272727;
  display: flex;
  flex-wrap: wrap;
  &-img
  {
    img
    {
      display: block;
    }
  }
  &-cont
  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 136px;
    flex-grow: 1;
    max-width: 683px;

    &_text
    {
      color: #FFF;
      font-family: "omnes-pro", sans-serif;
      font-size: 41px;
      line-height: 44px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      strong
      {
        font-weight: 500;
      }
    }

    .gform_wrapper
    {
      margin: 0;
      padding-top: 27px;
      form
      {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        .gform_body
        {
          flex-grow: 1;
          width: auto;
          max-width: 261px;
        }
        .gform_footer
        {
          margin: 0;
          padding: 0;
          width: auto;
          position: relative;
          input.button,
          input[type=submit]
          {
            margin: 0;
            background: #73CCDC;
            border: none;
            color: #FFF;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 22px 22px 0;
            text-transform: uppercase;
            padding: 0 40px;
            outline: 0;
          }
          img
          {
            position: absolute;
            right: 8px;
            top: 50%;
            margin-top: -12px;
            width: auto;
            height: 24px;
            display: block;
          }

        }
        .validation_error
        {
          width: 100%;
        }
      }
      ul.gform_fields li.gfield
      {
        margin: 0;
        padding: 0;
        position: relative;
        label
        {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 44px;
          display: flex;
          padding: 0 24px;
          align-items: center;
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
        }
        .top_label div.ginput_container
        {
          margin: 0;
        }
      }
    }
    .gform_wrapper .top_label div.ginput_container
    {
      margin-top: 0;
    }
    .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])
    {
      background: #FFF;
      border: none;
      color: #4C4C4C;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 22px 0 0 22px;
      padding: 0 24px;
      width: 100%;
      outline: 0;
    }
    .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half)
    {
      max-width: 9999em !important;
      width: auto !important;
    }

  }
}
body
{
  .gform_wrapper div.validation_error
  {
    color: #FF392A;
    border-color: #FF392A;
  }
  .gform_wrapper .gfield_error .gfield_description
  {
    color: #FF392A;
  }
  .gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_wrapper li.gfield_error textarea
  {
    border: none;
  }
  .gform_wrapper li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning
  {
    border: none;
    background: none;
  }
}
footer
{
  ul
  {
    margin: 0;
    list-style: none;
  }
  .wrapper
  {
    background-color: #272727;
  }
  .nav-menu
  {
    ul
    {
      margin: 0;
    }
  }
  p
  {
    margin: 0;
  }
}
.list-cities
{
  display: flex;
  align-items: center;
  justify-content: center;
  ul
  {
    padding: 0;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    li
    {
      &::before
      {
        content: '•';
        display: inline-block;
        color: #FFF;
      }
      &:first-child
      {
        &::before
        {
          display: none;
        }
      }
      a
      {
        padding: 0 18px;
        line-height: 75px;
        color: #FFF;
        display: inline-block;
        text-decoration: none;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        &:hover
        {
          color: #73CCDC;
        }
      }
    }
  }
}
.bloc-menu-foot
{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 50px;
  .bloc-social
  {
    display: flex;
    align-items: center;
    a
    {
      display: inline-block;
      padding-right: 20px;
    }
  }
  .last
  {
    width: 100%;
    text-align: right;
    padding: 40px 32px
  }
  .bloc-footerimg
  {
    display: flex;
    align-items: flex-end;
  }
  .nav-menu
  {
    display: flex;
    align-items: center;
    padding: 8px 50px;
    width: 500px;
    flex-wrap: wrap;
    ul
    {
      padding: 0;
    }
    li
    {
      display: inline-block;
    }
    a
    {
      font-size: 14px;
      color: #FFF;
      text-transform: uppercase;
      text-decoration: none;
      line-height: 24px;
      width: 197px;
      display: inline-block;
      letter-spacing: 0.08em;
    }
  }
}
.news-content
{
  .search-filter-results
  {
    height: 100%;
  }
}
.actu
{
  display: flex;
  &-home
  {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .bloc-actu
    {
      width: 100%;
    }
  }
  &-img
  {
    width: 235px;
    height: 164px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    img
    {
      height: 164px;
      width: auto;
    }
  }
  &-cont
  {
    border: 1px solid #E6E6E6;
    border-left: none;
    width: 407px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 43px;
    position: relative;
    &::before
    {
      content: '+';
      height: 44px;
      width: 44px;
      background-color: #000;
      color: #FFF;
      border-radius: 22px;
      position: absolute;
      top: 50%;
      margin-top: -22px;
      right: -22px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }

    &_title
    {
      font-size: 22px;
      line-height: 26px;
      text-decoration: none;
      text-transform: uppercase;
      font-family: "omnes-pro", sans-serif;
      color: #272727;
      padding-bottom: 12px;
    }
    &_text
    {
      p
      {
        margin: 0;
        color: #4C4C4C;
        font-size: 14px;
      }
    }
  }
  &-tags
  {
    a
    {
      display: inline-block;
      background: #000;
      color: #FFF;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 24px;
      height: 24px;
      letter-spacing: 0.14em;
      margin-right: 3px;
      padding: 0 10px;
      &:hover
      {
        background-color: #484848;
      }
    }
  }
}
.bloc-actu
{
  margin-bottom: 32px;
  a
  {
    text-decoration: none;
  }
}
/* Partie Produit */

.introduction-produit
{
  .fil-ariane
  {
    max-height: 50px;
    padding-left: 130px;
    padding-top: 22px;
    a
    {
      text-decoration: none;
      color: #000;
    }
  }
  .titre-page
  {

    border-bottom: 4px solid #73CCDC;
    font-family: "Omnes-pro", sans-serif;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;

  }
  .expertise-fond_text
  {
    left: -12px;
    text-transform: uppercase;
    padding-top: 50px;
  }
  .expertise-content
  {
    width: 75%;
    padding-bottom: 99px;
    .texte-introduction-produit
    {
      line-height: 25px;
      padding-bottom: 25px;
    }
    .devis
    {
      position: absolute;
      top: 15px;
      right: 60px;
    }
  }

}
/* Slider Produit */

.slider-produit
{
  position: relative;
  .rectangleB-produit
  {
    background-color: #FFFF;
    width: 589px;
    height: 238px;
    position: absolute;
    bottom: 255px;
    left: 158px;
    .promo
    {
      position: absolute;
      top: -20px;
      left: -20px;
      background: url(images/promo_expertise.png) top left no-repeat;
      width: 168px;
      height: 111px;

      span
      {
        color: #FFF;
        font-family: 'Oswald', sans-serif;
        font-size: 20px;
        position: relative;
        display: block;
        transform: rotate(-32deg);
        text-align: center;
        left: -26px;
        top: 27px;
      }
    }
    .texte-rectangleB-slider
    {
      p
      {
        font-size: 21px;
        padding-left: 48px;
        &:nth-child(1)
        {
          padding-top: 25px;
        }
      }
    }
  }
}
/* Texte Secondaire et Résultat */

.contenu-resultat
{
  display: flex;
  padding-top: 68px;
  padding-bottom: 60px;
  justify-content: space-evenly;
  .texte-secondaire-produit
  {
    width: 458px;
    height: 216px;
    text-align: justify;
    line-height: 25px;
  }
  .resultat-produit
  {
    text-align: justify;
    .titre-resultat-produit
    {
      color: #73CCDC;
      font-family: "Omnes-pro", sans-serif;
      font-weight: 500;
      font-size: 30px;
      text-transform: uppercase;
    }
    .texte-resultat
    {
      background-color: #73CCDC;
      color: #FFFF;
      width: 459px;
      height: 141px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 28px;
      position: relative;
      line-height: 25px;
    }
  }
}
/* Etapes Projet */

.titre-etapes-projet
{
  color: #73CCDC;
  font-family: "Omnes-pro", sans-serif;
  font-weight: 500;
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 39px;
}
.etapes-produit
{
  width: calc(100% / 4);
  //height: 300px;
  justify-content: center;
  flex-direction: column;
  padding: 30px 50px;
  position: relative;
  line-height: 25px;
  &:nth-child(odd)
  {
    background: url(images/rectangle-etape.svg) no-repeat;
    background-size: contain;
  }
  .sous-titre-etapes-projet
  {
    font-family: "Omnes-pro", sans-serif;
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
    display: block;
    &::after
    {
      background-color: #73CCDC;
      content: '';
      display: block;
      height: 3px;
      width: 65px;
      position: absolute;
      margin-top: 10px;

    }
  }
}
/* Bandeau projet */

.projectP
{
  display: flex;
  position: relative;
  background-color: transparent;
  &-img
  {
    img
    {
      display: block;
    }
  }
  &-cont
  {
    flex-direction: column;
    padding: 50px 230px;
    flex-grow: 1;
    max-width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
    align-items: stretch;

    .gform_wrapper
    {
      margin: auto;
      padding: 30px 79px;
    }
  }
}
/* Partie Flexible */

.content_flexible
{
  background: #f5f5f5;
  .bloc-flexible{
    background: #f5f5f5;
  }
  .introduction-flexible
  {
    .fil-ariane
    {
      max-height: 50px;
      padding-left: 130px;
      padding-top: 22px;
      a
      {
        text-decoration: none;
      }
    }
  }
    .texte-flexible
    {
      width: 414px;
      height: 210px;
      background-color: #FFFF;
      padding-left: 53px;
      padding-right: 54px;
      display: inline-block;
      &::before
      {
        background-color: #73CCDC;
        content: '';
        display: block;
        height: 3px;
        width: 65px;
        position: absolute;
        margin-top: 31px;
      }
      h2
      {
        color: #73CCDC;
        font-family: "Omnes-pro", sans-serif;
        font-weight: 500;
        font-size: 30px;
        text-transform: uppercase;
        padding-top: 13px;
      }
      p
      {
        text-align: justify;
      }
    }
  .partenaires{
    background: #fff;
  }

    .projectP
    {
      .projectP-img
      {
        img
        {
          width: 100%;
        }
      }
      .projectP-cont
      {
        padding: 50px 230px;
        .gform_wrapper
        {
          padding: 25px;
        }
      }
    }
    /**
  * FLEXIBLE PAGE SECTIONS
  * _________________
  **/

    // BLOCKS LEFT RIGHT
    .section-blocks-lre
    {
      .flexible-gauche
      {
        padding-left: 152px;
        padding-bottom: 50px;
        .block-lr1{
          display: inline-flex;
          .block-lr__text{
            margin-right: 50px;
          }
        }
      }

      .flexible-droite
      {
        padding-right: 152px;
        min-height: 350px;
        .block-lr1{
          float: right ;
          max-height: 350px;
        }
        .block-lr__text{
          //top: 63%;
          //position: absolute;
          //left: 14.4%;
          //&:nth-child(2){
          //  top: 103%;
          //}
          position: relative;
          right: 78.5%;
          top: -260px;
        }
      }

      .block-lr__img
      {
        display: block;
        height: auto;
        max-width: 729px;

        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .block-lr__text
      {
        padding: 5px 0.75em;
        width: 414px;
        height:210px;
        background-color: #fff;
        margin: auto;
        h2
        {
          color: #73CCDC;
          font-family: "Omnes-pro", sans-serif;
          font-weight: 500;
          font-size: 30px;
          max-width: 308px;
          height: auto ;
          margin-left: auto;
          margin-right: auto;
          &::before{
            background-color: #73ccdc;
            content: '';
            display: block;
            height: 3px;
            width: 65px;
            position: absolute;
            margin-top: -5px;

          }
        }
        p{
          max-width: 308px;
          max-height: 70px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
  @import "responsive";