@import "hamburgers/hamburgers";
@media screen and (max-width: 1365px)
{

  .slider
  {

    width: 100%;
  }
}
@media #{$fat}
{
  .promo-text_titre
  {
    padding-left: 0px;
    text-align: center;
    max-width: 100%;
    padding-top: 70px;
  }
  .promo-text_content
  {
    padding-left: 0px;
    text-align: center;
  }

  .promo-rectangle_blanc
  {
    margin-top: 100px;
  }

  .expertise-fond_text
  {
    font-size: 140px;
  }
  .parrainage
  {
    img
    {
      max-width: 100%;
    }
    .partenaires-logos
    {
      display: flex;
    }
  }

  .news
  {
    .bloc-onglets
    {
      .expertise-fond_text
      {
        font-size: 140px;
      }
      .expertise-content > *
      {
        padding-left: 80px;
      }
      .news-text
      {
        padding-left: 90px;
        padding-right: 90px;
      }
    }
  }

  .news-content
  {
    width: 100%;
    .actu-cont
    {
      &::before
      {
        right: 10px;
      }
    }
  }

  .project
  {
    flex-wrap: initial;
    .project-cont
    {
      padding: 0px;
      .project-cont_text
      {
        text-align: center;
        padding: 10px;
      }
      form
      {
        display: -webkit-box;
        padding: 10px;
      }
    }
  }

}
@media #{$responsiveBis,$tabletXl}
{
  .header
  {

    height: 100px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

  }

  .header ul
  {

    flex-wrap: wrap;
  }

  .jointeam-content_rectangle
  {
    width: 180px;
    right: -30px;
  }
  .jointeam-content_logo
  {
    left: 60px;

  }

  .menu2-nav-top
  {

    display: flex;
    padding: 0;
    margin: 0;

    > li
    {

      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;

    }
  }

  .numero-devis_content
  {
    margin-top: 15px;
  }

  .promo-rectangle_blanc
  {

    width: 90px;
    height: 100px;
    margin-left: 80%;
    margin-top: 20%;

  }

  .promo-rectangle_bleu
  {
    text-align: center;
    .promo-text_content
    {
      padding-left: 0px;
    }
  }

  .promo-rectangle_vert
  {
    width: 80px;
    height: 90px;
    position: absolute;
    margin: 5px;

  }

  .promo-rectangle_30
  {
    font-size: 10px;
  }
  .parrainage
  {
    width: 100%;
    img
    {
      width: 100%;
    }
    .parrainage-cont
    {
      padding-right: 0px;
      .parrainage-bloc
      {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        .onglet-bouton
        {
          margin: 10px 0 10px 0;
        }
      }
    }
  }

  .partenaires
  {
    flex-wrap: wrap;
    height: auto;
    padding: 20px 0 20px 0;
    .partenaires-title
    {
      display: flex;
      margin: auto;
      padding: 15px;
    }
    .partenaires-logos
    {
      img
      {
        margin: auto;
        display: flex;
      }
    }
  }

  .news .bloc-onglets .expertise-fond_text
  {
    font-size: 6.5em;
    left: 3px;
  }

  .expertise-fond_text
  {
    font-size: 6.5em;
    left: 3px;
  }
  .news
  {
    display: block;
    .bloc-onglets
    {
      width: 100%;
    }
    .news-content
    {
      width: 100%;
      .actu-cont
      {
        &::before
        {
          right: 10px;
        }
      }
      .bloc-actu
      {
        width: auto;
      }
    }
  }

}
@media #{$responsiveBis}{

  .jointeam-content
  {
    display: none;
  }
  .logo-engagements
  {
    margin-left: 5px;
  }
  .actu-home
  {
    .bloc-actu
    {
      width: auto;
      .actu
      {

        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
@media #{$responsive}
{

  .slider
  {

    width: 100%;
  }

  .hamburger
  {

    padding-top: 35px;

  }

  .promo
  {

    width: 100%;
  }

  .promo-rectangle_bleu
  {

    width: 360px;
  }

  .slider-image1
  {

    object-fit: cover;

  }

  .bandeau-info li
  {
    width: 50%;
    font-size: 0.7em;
  }

  .logo-engagements
  {
    margin-left: 20px;
  }

  .menu-nav-top
  {

    flex-wrap: wrap;
    font-size: 0.5em;
    order: 2;

  }

  .logo-site
  {

    width: 88px;
    height: 50px;
    margin-left: 10px;

  }

  li.sous-nav1
  {

    /* display : none; */
    padding: 0;
    margin: 0;

  }

  .jointeam
  {

    display: none;
  }

  .jointeam-content_rectangle
  {

    width: 120px;
    height: 80px;

  }

  .navigation
  {

    background: RGBA(0, 0, 0, 0.8);
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    width: 80%;
    z-index: 10001;
    margin-right: -100%;
    bottom: 0;

  }

  .header ul
  {

    background: RGBA(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    margin-left: -100%;
    bottom: 0;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 10%;
    padding-bottom: 10%;

  }

  .lien-menu-nav
  {

    text-decoration: none;
    color: #FFF;
  }

  .lien-menu-nav span
  {

    position: relative;
    display: block;

  }

  .lien-menu-nav span::before
  {

    content: '';
    display: block;
    background-color: aquamarine;
    height: 4px;
    width: 0;
    position: absolute;
    bottom: -6px;
    right: 50%;
    transition: width .2s ease-in-out;

  }

  .lien-menu-nav:hover span::before
  {

    width: 50%;
  }

  .lien-menu-nav span::after
  {
    content: '';
    display: block;
    background-color: aquamarine;
    height: 4px;
    width: 0;
    position: absolute;
    bottom: -6px;
    left: 50%;
    transition: width .2s ease-in-out;

  }

  .lien-menu-nav:hover span::after
  {

    width: 50%;
  }

  .header .header-button
  {

    justify-content: flex-end;
    order: 3;
    display: block;
    position: static;
    right: 0;
    top: 0;
    z-index: 10002;

  }

  .header .nav-button
  {

    justify-content: flex-start;
    display: block;
    position: static;
    left: 0;
    top: 0;
    z-index: 10001;

  }

  .open-menu
  {

    .navigation
    {

      margin-right: 0;
      transition: margin-right 0.8s;
    }

    .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before
    {

      width: 40px;
      height: 4px;
      background-color: #FFF;
      border-radius: 4px;
      position: absolute;
      -webkit-transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      -webkit-transition-duration: .15s;
      transition-duration: .15s;
      -webkit-transition-timing-function: ease;
      transition-timing-function: ease;
      display: block;
    }

  }
  .open-menu_nav
  {

    .header ul
    {

      margin-left: 0;
      transition: margin-left 0.8s;
    }

    .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before
    {

      width: 40px;
      height: 4px;
      background-color: #FFF;
      border-radius: 4px;
      position: absolute;
      -webkit-transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      -webkit-transition-duration: .15s;
      transition-duration: .15s;
      -webkit-transition-timing-function: ease;
      transition-timing-function: ease;
      display: block;
    }

  }

  .menu2-nav-top
  {

    flex-wrap: wrap;

  }

  .onglets
  {

    width: 90%;
    height: auto;
    padding-bottom: 15px;

  }

  .bloc-onglets
  {

    width: 100%;
  }

  .expertise-fond_text
  {

    font-size: 4.5em;
  }

  .expertise-content
  {

    font-size: 0.9em;
    margin-top: 25px;

  }

  .text-onglet_dessous
  {

    font-size: 1em;

  }

  .text-onglet_bleu
  {

    font-size: 1.5em;

  }

  .promo-rectangle_bleu
  {

    position: absolute;
    height: 40%;
    width: 72%;
    background: #73CCDC;
    top: 250px;
    left: 50px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  .promo-text_titre
  {

    font-size: 25px;
    padding: 0;

  }

  .promo-text_content
  {

    font-size: 15px;
    padding: 0;

  }

  .promo-rectangle_bleu__profitez
  {

    width: 180px;
    justify-content: center;
    margin-top: 10px;
    margin-left: 0;
  }

  .promo-profitez_text
  {
    font-size: 1em;
  }

  .promo-rectangle_blanc
  {

    width: 79px;
    height: 89px;
    margin-top: 55%;
    margin-left: 70%;
    box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.16);

    @media screen and (max-width: 320px)
    {
      margin-top: 65%;
    }

  }

  .promo-rectangle_vert
  {

    width: 70px;
    height: 80px;
    position: absolute;
    margin: 5px;

  }

  .promo-rectangle_30
  {

    font-size: 10px;

  }

  .promo-30pourcent
  {

    color: #FFF;
    font-size: 20px;

  }

  .expertise-fond_text
  {

    font-size: 3em;
    left: 3px;
    @media screen and (max-width: 320px)
    {
      font-size: 2.5em;

    }

  }

  .parrainage
  {
    width: 100%;
    img
    {
      width: 100%;
    }
    .parrainage-cont
    {
      padding-right: 0px;
      .parrainage-bloc
      {
        height: auto;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        .onglet-bouton
        {
          margin: 10px 0 10px 0;
        }
      }
    }
  }
  .partenaires
  {
    flex-wrap: wrap;
    height: auto;
    padding: 20px 0 20px 0;
    .partenaires-title
    {
      display: flex;
      margin: auto;
      padding: 15px;
    }
    .partenaires-logos
    {
      display: block;
      img
      {
        margin: auto;
        display: flex;
      }
    }
  }

  .slider-wrapper
  {
    max-width: 100%;
  }

  .news
  {
    display: block;
    .bloc-onglets
    {
      width: 100%;
      .news-text
      {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .news-content
    {
      width: 100%;
      .actu-cont
      {
        &::before
        {
          height: 34px;
          width: 34px;
          margin-top: -22px;
          right: 3px;
        }
      }
    }
  }
}
@media #{$responsive,$responsiveBis}{

  .project
  {
    .project-img
    {
      width: 100%;
      img
      {
        width: 100%;
      }
    }
    .project-cont
    {
      padding: 0px;
      max-width: 100%;
      .project-cont_text
      {
        text-align: center;
        font-size: 30px;
        padding-top: 15px;
      }
      .gform_wrapper
      {
        @media screen and (max-width: 320px)
        {
          form
          {
            display: -webkit-inline-box;
            .gform_body
            {
              margin-left: 0;
            }
            .gform_footer
            {
              margin-right: 0;
            }

          }
        }
        padding: 25px 0 25px 0;
        .gform_body
        {
          max-width: 180px;
          margin-left: auto;
        }
        .gform_footer
        {
          margin-right: auto;
        }
      }

    }
  }
  .news .bloc-onglets .expertise-fond_text
  {
    font-size: 4.5em;
    left: 3px;
  }

  .list-cities
  {
    ul
    {
      display: block;
    }
    .marker-city
    {
      position: relative;
      top: -150px;
      right: -20px;
    }
  }
  .bloc-menu-foot
  {
    padding-top: 20px;
    .nav-menu
    {
      width: 100%;
      li
      {
        margin: auto;
        text-align: center;
      }
    }
    .social
    {
      a
      {
        &:nth-child(2)
        {
          padding-left: 20px;
          padding-right: 0px;
        }
      }
    }
    .last
    {
      text-align: center;
    }
  }
}
/* PAGE PRODUIT */
@media #{$fat}{
  .content_produit
  {

    .slider-produit
    {
      img
      {
        max-width: 100%;
      }
    }

    .bloc-onglets_content
    {
      padding: 0 10px 44px 10px;
      .etapes-produit
      {
        width: calc(100% / 2);
        margin-top: 10px;
        background-size: auto;
        .sous-titre-etapes-projet
        {
          max-width: 300px;
        }
        .texte-descriptif-produit
        {
          max-width: 300px;
          display: flex;
        }
      }
    }
    .projectP
    {
      .projectP-img
      {
        img
        {
          width: 100%;
        }
      }
      .projectP-cont
      {
        padding: 25px 110px;
        .gform_wrapper
        {
          padding: 0px;
        }
      }
    }
    .bloc-onglets_content
    {
      padding-bottom: 44px;
    }
  }
}
@media #{$responsiveBis, $tabletXl}{
  .content_produit
  {
    .slider-produit
    {
      img
      {
        max-width: 100%;
      }
    }
    .contenu-resultat
    {
      padding-top: 25px;
      display: block;
      .texte-secondaire-produit
      {
        height: auto;
        width: 100%;
        padding: 0 85px 0 85px;
      }
      .resultat-produit
      {
        padding: 0 85px 0 85px;
        .titre-resultat-produit
        {
          text-align: center;
        }
        .texte-resultat
        {
          width: 100%;
          padding: 10px;
          height: auto;
        }
      }
    }

    .projectP-img
    {
      img
      {
        width: 100%;
      }
    }
    .projectP-cont
    {
      padding: 15px 110px;
      .project-cont_text
      {
        padding-top: 0px;
      }
      .gform_wrapper
      {
        padding-top: 10px;
      }
    }

    .bloc-onglets_content
    {
      padding: 0 10px 44px 10px;
      .etapes-produit
      {
        width: calc(100% / 2);
        margin-top: 10px;
      }
    }
  }
}
@media #{$responsive}{
  .content_produit
  {
    .introduction-produit
    {
      .fil-ariane
      {
        padding-left: 15px;
      }
      .promo-profitez_text
      {
        font-size: 0.8em;
        text-align: center;
      }
      .expertise-fond_text
      {
        left: -5px;
        padding-top: 65px;
      }
      .expertise-content
      {
        padding-bottom: 40px;
        margin-top: 50px;
        .devis
        {
          top: 45px;
          right: 10px;
          min-width: 120px;
        }
      }
    }

    .slider-produit
    {
      img
      {
        max-width: 100%;
      }
      .rectangleB-produit
      {
        display: contents;
        position: relative;
        width: 100%;
        .promo
        {
          top: 438px;
        }
        .texte-rectangleB-slider
        {
          p:nth-child(1)
          {
            margin-top: 50px;
          }
        }
      }
    }

    .contenu-resultat
    {
      padding-top: 25px;
      display: block;
      .texte-secondaire-produit
      {
        height: auto;
        width: 100%;
        padding: 0 10px 0 10px;
      }
      .resultat-produit
      {
        padding: 0 10px 0 10px;
        .titre-resultat-produit
        {
          text-align: center;
        }
        .texte-resultat
        {
          width: 100%;
          padding: 10px;
          height: auto;
        }
      }
    }
    .projectP-img
    {
      img
      {
        width: 100%;
      }
    }

    .titre-etapes-projet
    {
      span
      {
        padding: 0 10px 0 10px;
      }
    }
    .bloc-onglets_content
    {
      padding-bottom: 40px;
      .etapes-produit
      {
        display: contents;
        .texte-descriptif-produit
        {
          padding: 20px 10px 20px;
          text-align: justify;
        }
      }
    }
  }
}
@media #{$mobile}{
  .content_produit
  {
    .introduction-produit
    {
      .expertise-content
      {
        .devis
        {
          min-width: 110px;
          height: 30px;
          .promo-profitez_text
          {
            font-size: 0.7em;
          }
        }
      }
    }
    .contenu-resultat
    {
      padding-bottom: 40px;
      .titre-resultat-produit
      {
        padding-top: 40px;
      }
    }
    .bloc-onglets_content
    {
      .etapes-produit
      {
        .texte-descriptif-produit
        {
          padding: 20px 10px 20px;
        }
      }
    }
  }
}
/* PAGE FLEXIBLE */

@media #{$fat}{
  .content_flexible
  {

    .section-blocks-lre
    {
      .flexible-gauche, .flexible-droite
      {
        padding: 0;
        width: 100%;

        .block-lr1
        {
          float: initial;
          max-height: none;
          display: block;
          width: 100%;
          .block-lr__img{
           margin: auto;
          }
          .block-lr__text
          {
            margin: auto;
            margin-top: 15px;
            margin-bottom: 15px;
            position: initial;
          }
        }
      }
    }
    .partenaires
    {
      flex-wrap: wrap;
      height: auto;
      padding: 20px 0;
      .partenaires-title{
        margin: auto;
      }
    }

    .projectP
    {
      .projectP-img
      {
        img
        {
          width: 100%;
        }
      }
      .projectP-cont
      {
        padding: 25px 110px;
        .gform_wrapper
        {
          padding: 0px;
        }
      }
    }

  }
}
@media #{$responsiveBis,$tabletXl}{
  .content_flexible
  {
    .section-blocks-lre
    {
      width: 100%;
      .flexible-gauche, .flexible-droite
      {
        padding: 0;
        width: 100%;
        img
        {
          width: 100%;
        }
        .block-lr1
        {
          float: initial;
          max-height: none;
          display: block;
          width: auto;
          height: auto;
          .block-lr__img{
            max-width: 100%;
          }
          .block-lr__text
          {
            margin: auto;
            margin-top: 15px;
            margin-bottom: 15px;
            position: initial;
          }
        }
      }
    }
    .projectP
    {
      .projectP-cont
      {
        padding: 15px 110px;
        .project-cont_text
        {
          padding-top: 0px;
        }
        .gform_wrapper
        {
          padding-top: 10px;
        }
      }
    }
  }
}
  @media #{$mobile, $responsive}{
    .content_flexible
    {
      .introduction-flexible
      {
        .fil-ariane
        {
          padding-left: 15px;
        }
        .expertise-content > *
        {
          padding-top: 55px;
        }
        .expertise-fond_text
        {
          padding-top: 75px;
        }
        .contenu-global
        {
          img
          {
            width: 100%;
          }
          .texte-flexible
          {
            max-width: 100%;
            padding: 0 15px 0 15px;
          }
        }
      }

      .section-blocks-lre
      {
        width: 100%;
        .flexible-gauche, .flexible-droite
        {
          padding: 0;
          width: 100%;
          img
          {
            width: 100%;
          }
          .block-lr1
          {
            display: block;
            width: auto;
            .block-lr__text
            {
              width: 100%;
              height: auto ;
              margin: auto;
              margin-top: 15px;
              margin-bottom: 15px;
              position: initial;
            }
          }
        }
      }

      .partenaires{
        padding: 40px 0;
      }

      .projectP
      {
        .projectP-img
        {
          img
          {
            width: 100%;
          }
        }
        .projectP-cont
        {
          padding: 0;
          .gform_wrapper
          {
            padding: 0px;
          }
        }
      }
    }
  }